<template>
  <div class="ProfileUserEdit">
    <div class="title w-6">Update Profile</div>

    <ValidationObserver ref="observer"
                        v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)">
        <base-textbox label="Full Name"
                      placeholder="Your Full Name"
                      v-model="profile.name" />
        <base-textbox label="Phone Number"
                      placeholder="Your Phone Number"
                      v-model="profile.phone_number" />
        <base-textbox label="Email"
                      placeholder="Your email"
                      v-model="profile.email"
                      description="When changing the email you must enter your password"
                      @input="onEmailChange"/>
        <base-textbox v-if="!isDisabled"
                      label="New Password"
                      placeholder="Your New Password"
                      v-model="password"
                      show-password-toggle
                      type="password"
                      @input="onPasswordChange"/>
        <template v-if="showCurrentPassword && hasPassword && !isDisabled">
          <base-textbox label="Current Password"
                        placeholder="Your Current Password"
                        type="password"
                        show-password-toggle
                        v-model="password_confirmation"
                        rules="required" />
        </template>

        <h3 class="mt-4">Extra information</h3>
        <base-textbox label="City"
                      placeholder="Your City"
                      v-model="profile.billing_city" />
        <base-textbox label="State"
                      placeholder="Your State"
                      v-model="profile.billing_state" />
        <base-textbox label="Postal code"
                      placeholder="Your Postal Code"
                      v-model="profile.billing_postal_code" />
        <base-dropdown label="Country"
                       item-label="title"
                       :searchable="true"
                       track-by="type"
                       v-model="selectedCountry"
                       :options="countries"
                       smallLabel />
        <base-dropdown label="Tax Country"
                       item-label="title"
                       :searchable="true"
                       track-by="type"
                       v-model="selectedTaxType"
                       :options="taxTypes"
                       clearable
                       smallLabel />
        <base-textbox :label="billingTaxLabel"
                      :placeholder="selectedTaxType ? 'Ex: '  + selectedTaxType.placeholder : 'Ex: 1234-1212-32'"
                      :disabled="!selectedTaxType"
                      v-model="profile.billing_tax.value" />
        <base-dropdown label="Timezone"
                       item-label="title"
                       :searchable="true"
                       track-by="type"
                       v-model="selectedTimezone"
                       :options="timezones"
                       smallLabel />
        <base-textarea label="Address"
                       placeholder="Your Address"
                       rows="5"
                       v-model="profile.billing_address" />
        <base-checkbox label="Send weekly email reports about my websites"
                       v-model="profile.send_weekly_reports" />

        <div class="d-flex justify-content-end">
          <base-button type="button"
                       color="primary"
                       :outlined="true"
                       @click="$emit('close')">Close</base-button>
          <base-button type="submit"
                       color="primary"
                       class="submit-button ml-2"
                       :disabled="!profile.email"
                       :loading="submitting">Update</base-button>
        </div>
      </form>
    </ValidationObserver>
    <base-alert type="error" v-if="validationErrors" class="mt-3">
      <ul class="list-unstyled">
        <li v-for="value in validationErrors" :key="value">{{ value }}</li>
      </ul>
    </base-alert>
  </div>
</template>

<script>
import submittable from '@/mixins/submittable.js'
import { cloneObject } from '@/services/utils.js'
import profileApi from '@/api/profileApi.js'
import BaseAlert from '../../../Base/BaseAlert'
import { timezones } from './timezonesModel'
import Swal from 'sweetalert2'
import { taxTypes } from '@/components/Account/Profile/ProfileUser/taxTypesModel'

export default {
  components: { BaseAlert },
  mixins: [
    submittable
  ],

  props: {
    countries: {
      required: true
    }
  },

  data () {
    return {
      password: null,
      profile: null,
      submitting: false,
      error: false,
      errorMessage: 'Something went wrong ',
      validationErrors: '',
      selectedCountry: null,
      selectedTimezone: null,
      timezones: null,
      password_confirmation: null,
      isEmailChanging: false,
      isPasswordChanging: false,
      hasPassword: false,
      taxTypes: null,
      selectedTaxType: null,
      taxNumber: ''
    }
  },

  created () {
    this.profile = cloneObject(this.user)
    this.timezones = timezones
    this.userHasPassword()
    this.taxTypes = taxTypes
  },

  mounted () {
    this.selectedCountry = this.countries.find(item =>
      item.type === this.profile.billing_country
    )

    this.selectedTaxType = this.taxTypes.find(item => {
      if (this.profile.billing_tax && this.profile.billing_tax !== []) {
        return item.code === this.profile.billing_tax.country && item.type === this.profile.billing_tax.type
      }
    })

    if (this.profile.timezone) {
      let data = JSON.parse(this.profile.timezone)
      this.selectedTimezone = this.timezones.find(item =>
        item.utc[0] === data['utc'] &&
        item.title === data['title']
      )
    }
  },

  methods: {
    async userHasPassword () {
      this.hasPassword = await profileApi.hasPassword()
    },

    async onFormSubmit () {
      this.error = false
      try {
        this.profile['password'] = this.password
        this.profile['password_confirmation'] = this.password_confirmation
        this.profile.billing_country = this.selectedCountry ? this.selectedCountry.type : ''
        this.profile.timezone = this.selectedTimezone ? { 'utc': this.selectedTimezone.utc[0], 'title': this.selectedTimezone.title } : ''
        this.profile.billing_tax = { ...this.profile.billing_tax, type: this.selectedTaxType?.type, country: this.selectedTaxType?.code }
        await profileApi.update(this.profile)
        this.$emit('update')
        this.showSuccessMessage()
      } catch (error) {
        // if (error.response && error.response.status === 422) {
        //   this.validationErrors = Object.values(error.response.data.errors).flat()
        // }
        if (error.response.data?.message !== '') {
          this.errorMessage = error.response.data.message
        }
        this.error = true
        this.showErrorMessage()
      }
    },

    onPasswordChange () {
      this.isPasswordChanging = this.password.length > 0
    },

    onEmailChange () {
      this.isEmailChanging = this.user['email'] !== this.profile['email']
    },

    ...mapActions('authentication', [
      'refreshCurrentUser'
    ]),

    showSuccessMessage () {
      Swal.fire({
        title: 'Success',
        text: 'The data was updated successfully',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        confirmButtonColor: '#6652e0',
        allowOutsideClick: true
      })
    },

    showErrorMessage () {
      Swal.fire({
        title: 'Oops',
        text: this.errorMessage,
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        allowOutsideClick: true
      })
    }
  },

  computed: {
    ...mapState('authentication', [
      'user'
    ]),
    isDisabled () {
      if (this.user.sign_in_method === 'google') {
        return true
      }
      return false
    },

    showCurrentPassword () {
      return this.isPasswordChanging || this.isEmailChanging
    },

    billingTaxLabel () {
      if (this.user.billing_tax.status) {
        return 'Tax No. (' + this.user.billing_tax.status + ')'
      }
      return 'Tax No.'
    }
  }
}
</script>

<style lang="scss" scoped>
  .ProfileUserEdit {
    padding: 30px;
    background-color: map-get($colors, background);

    .title {
      font-size: 24px;
      margin-bottom: 10px;
    }

    .submit-button {
      width: 150px;
    }

    .list-unstyled {
      list-style-type: none;
    }
  }
</style>
